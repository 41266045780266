.back-to-top {
	height: 0px;
}
.top {
	height: 0px;
}

.top-button {
	position: fixed;
	left: auto;
	top: auto;
	right: 12px;
	bottom: 12px;
	z-index: 10;
	display: flex;
	width: 44px;
	height: 44px;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: var(--color-back-to-top);

	transition: all 300ms ease-in;
	color: var(--color-default);
	font-size: 18px;
	cursor: pointer;

	&.show {
		visibility: visible;
	}
	&.hide {
		visibility: hidden;
	}
}

.top-button:hover {
	background-color: var(--color-red);
}

.top-button-icon {
	position: relative;
	margin: 0px auto 1px;
	background-color: var(--color-back-to-top-inner);
	fill: var(--color-back-to-top-inner);
	color: var(--color-back-to-top-inner);
	clip-path: polygon(48% 0, 100% 100%, 49% 50%, 0% 100%);
	
}
