@media screen and (max-width: 767px) {
    h2 {
        font-size: 28px;
        line-height: 36px;
    }

    .intro.margin {
        margin-bottom: 48px;
    }

    .intro.margin-small {
        margin-bottom: 40px;
    }

    .section {
        padding-top: 64px;
        padding-bottom: 64px;
    }

    .top-button {
        display: none;
    }

    .main-grid {
        grid-row-gap: 48px;
    }

    .navbar {
        min-height: 64px;
    }

    .nav-link {
        font-size: 15px;
        line-height: 20px;
    }

    .logo {
        height: 28px;
    }

    .logo-text {
        font-size: 20px;
        line-height: 28px;
    }

    .button.nav-button {
        display: none;
    }

    .page-heading {
        font-size: 7vw;
        line-height: 8vw;
    }

    .panel {
        grid-column-gap: 18px;
    }

    .panel-contact {
        margin-right: -8px;
    }

    .panel-text-label {
        margin-bottom: 2px;
        font-size: 12px;
        line-height: 18px;
    }

    .panel-email {
        font-size: 15px;
        line-height: 20px;
    }

    .panel-service {
        margin-right: 10px;
        font-size: 12px;
        line-height: 18px;
    }

    .top-section {
        padding-top: 96px;
        padding-bottom: 80px;
    }

    .footer-nav-link {
        margin-right: 28px;
    }

    .panel-social-links {
        margin-right: -10px;
    }

    .panel-social-link {
        padding-right: 10px;
        padding-left: 10px;
        font-size: 13px;
    }

    .section-angle {
        display: none;
    }

    .hero-v1-section {
        padding-bottom: 10vw;
    }

    .hero-v1-photo {
        width: 30vw;
        height: 30vw;
    }

    .hero-v1-info {
        padding-top: 0px;
    }

    .dynamic-text-wrapper {
        margin-top: -4px;
    }

    .dynamic-works {
        // padding-right: 25%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .works-grid-1 {
        width: 100%;
        max-width: none;
        margin-bottom: 48px;
        grid-row-gap: 48px;
    }

    .works-grid-2 {
        width: 100%;
        margin-left: 0%;
        grid-row-gap: 48px;
    }

    .counter {
        padding-top: 32px;
        padding-bottom: 32px;
    }

    .counter-number {
        font-size: 48px;
    }

    .testimonials-circle {
        width: 76vw;
        height: 76vw;
    }

    .review-tab-link {
        width: 60px;
        height: 60px;
    }

    .review-text {
        margin-top: 20px;
        font-size: 17px;
        line-height: 28px;
    }

    .review-author {
        font-size: 15px;
        line-height: 24px;
    }

    .pricing {
        max-width: 66vw;
    }

    .nav-menu {
        width: 240px;
        padding-top: 64px;
    }

    .accordion-item {
        padding-right: 16px;
        padding-left: 16px;
    }

    .accordion-header {
        min-height: 60px;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .accordion-heading {
        font-size: 17px;
        line-height: 24px;
    }

    .accordion-text {
        margin-right: 4px;
        margin-left: 4px;
        padding-bottom: 16px;
    }

    .instagram {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .instagram-info {
        width: 160px;
    }

    .instagram-photos {
        padding-left: 20px;
    }

    .instagram-heading {
        font-size: 20px;
        line-height: 28px;
    }

    .posts {
        grid-row-gap: 24px;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    }

    .post-card-content {
        margin-top: -32px;
        margin-right: 8%;
        margin-left: 8%;
    }

    .side-blocks {
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        grid-row-gap: 32px;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto;
    }

    .side-info {
        padding-right: 12%;
        padding-left: 12%;
    }

    .shop-item {
        padding: 24px;
    }

    .cart-button {
        margin-right: 0px;
    }

    .shop-slider-intro {
        padding-right: 8%;
        padding-left: 8%;
    }

    .product-sidebar {
        padding-top: 60px;
        padding-right: 16%;
        padding-left: 16%;
    }

    .tag-badge {
        margin-top: 4px;
        margin-right: 4px;
        margin-bottom: 4px;
    }

    .post-heading {
        font-size: 28px;
        line-height: 36px;
    }

    .order-sidebar {
        position: static;
        width: 100%;
    }

    .category-list {
        margin-bottom: 40px;
    }

    .work-nav {
        padding-top: 24px;
        padding-bottom: 24px;
        -ms-grid-columns: 1fr minmax(72px, auto) 1fr;
        grid-template-columns: 1fr minmax(72px, auto) 1fr;
    }

    .work-nav-preview {
        width: 72px;
        height: 72px;
    }

    .work-nav-info {
        padding-right: 20px;
        padding-left: 20px;
    }

    .work-nav-name {
        font-size: 15px;
        line-height: 20px;
    }

    .work-nav-button {
        width: 72px;
        height: 72px;
    }

    .work-nav-icon {
        width: 20px;
    }

    .service-info {
        padding: 40px 24px 24px;
    }

    .service-icon-box {
        padding: 24px;
    }

    .work-block {
        padding: 24px 32px;
    }

    .work-categories {
        margin-right: -4px;
    }

    .order {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
    }

    .order-content {
        margin-right: 0px;
    }

    .menu-icon {
        margin-right: 2px;
        margin-left: 2px;
    }

    .sg-colors {
        grid-column-gap: 12px;
        grid-row-gap: 12px;
    }

    .sg-color-card {
        padding: 8px;
    }

    .sg-color-name {
        margin-top: 10px;
        margin-bottom: 2px;
        font-size: 11px;
        line-height: 16px;
    }
    .section.no-padding-top.skillset{
		padding-top: 5rem;
	}
}