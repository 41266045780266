.dynamic-works {
	display: flex;
	margin-top: -8px;
	align-items: flex-start;
}
.dynamic-works-heading {
	margin-right: 24px;
	margin-bottom: 0px;
}
.dynamic-works-intro {
	position: relative;
	z-index: 1;
	display: flex;
	margin-right: auto;
	margin-left: 0px;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	text-align: left;
}
.side-link {
	position: relative;
	overflow: hidden;
	padding: 7px 16px 8px;
	border-left: 1px solid var(--color-side-link-border);
	transition: color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	color: var(--color-side-link);
	font-size: 13px;
	line-height: 16px;
	font-weight: 600;

	&-hover {
		position: absolute;
		left: 0%;
		top: 0%;
		right: auto;
		bottom: 0%;
		width: 3px;
		height: 100%;
		background-color: var(--color-red);
		width: 100%;
		transform: translate3d(-102%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
			rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
		transform-style: preserve-3d;
	}
	&:hover {
		border-left-color: transparent;
		color: var(--color-default);
		.side-link-hover {
			transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
				rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
			transform-style: preserve-3d;
		}
	}
	&-text {
		position: relative;
		z-index: 1;
	}
}

.w-layout-grid {
	display: grid;
	grid-auto-columns: 1fr;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto auto;
	grid-row-gap: 16px;
	grid-column-gap: 16px;
}

.works-grid-1 {
	width: 50%;
	max-width: 570px;
	grid-column-gap: 0px;
	grid-row-gap: 112px;
	grid-template-columns: 1fr;
	grid-template-rows: auto auto auto;
}

.works-grid-2 {
	margin-left: 11%;
	padding-top: 8px;
	flex: 1;
	grid-column-gap: 0px;
	grid-row-gap: 112px;
	grid-template-columns: 1fr;
}

.work-card {
	position: relative;
	display: flex;
	border-radius: 2rem;
	border: 1px solid var(--color-work-card-border);
	padding: 1rem;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	-webkit-transition: box-shadow 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
		-webkit-transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: box-shadow 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
		-webkit-transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
		box-shadow 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
		box-shadow 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
		-webkit-transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	will-change: transform;

	&-preview {
		position: relative;
		display: flex;
		overflow: hidden;
		width: 100%;
		margin-bottom: 5%;
		justify-content: center;
		align-items: center;

		&:hover {
			box-shadow: none;
			-webkit-transform: none;
			-ms-transform: none;
			transform: none;
		}

		.card-hover-circle {
			position: absolute;
			z-index: 3;

			display: flex;
			width: 60px;
			height: 60px;

			justify-content: center;

			align-items: center;
			border-radius: 50%;
			background-color: var(--color-default);
			background-image: url("../../img/preview-icon.png");
			background-position: 50% 50%;
			background-size: 24px;
			background-repeat: no-repeat;

			transform: translate3d(0px, 0px, 0px) scale3d(0, 0, 1) rotateX(0deg)
				rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
			transform-style: preserve-3d;
		}
		.bg-image {
			transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
				rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
			transform-style: preserve-3d;
			border-radius: 1rem;
		}
	}
	&-description {
		margin-top: -4px;
		color: var(--color-work-card-description);
		font-size: 15px;
		line-height: 24px;
	}
	&-heading {
		color: var(--color-work-card-heading);
	}

	&-categories {
		display: flex;
		margin-top: 16px;
		flex-wrap: wrap;
		align-items: center;
		grid-auto-columns: auto;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto auto;
	}

	&:hover {
		.card-hover-circle {
			transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
				rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
			transform-style: preserve-3d;
		}
		.bg-image {
			transform: translate3d(0px, 0px, 0px) scale3d(1.05, 1.05, 1) rotateX(0deg)
				rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
			transform-style: preserve-3d;
		}
	}
}

.intro-left {
	position: relative;
	z-index: 1;

	display: flex;
	margin-right: auto;
	margin-left: 0px;

	flex-direction: column;

	align-items: flex-start;
	text-align: left;
}

.overlay {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 100;
	display: none;
	width: 100%;
	height: 100%;
	margin: auto;
	padding: 50%;
	background-color: var(--color-default);
}

.overlay {
	display: none;
	transform: translate3d(150%, -150%, 0px) scale3d(2, 2, 1) rotateX(0deg)
		rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
	transform-style: preserve-3d;
}
