@media screen and (max-width: 991px) {
	h1 {
		font-size: 40px;
		line-height: 48px;
	}

	h2 {
		font-size: 32px;
		line-height: 40px;
	}

	h4 {
		font-size: 18px;
		line-height: 24px;
	}

	h5 {
		font-size: 16px;
		line-height: 22px;
	}

	h6 {
		font-size: 14px;
		line-height: 18px;
	}

	.wrapper {
		padding-right: 16px;
		padding-left: 16px;
	}

	.intro.margin {
		margin-bottom: 64px;
	}

	.section {
		padding: 80px 0px;
	}

	.section.post-section {
		padding-top: 104px;
	}

	.top-button {
		right: 12px;
		bottom: 12px;
	}

	.main-grid {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		grid-column-gap: 16px;
		grid-row-gap: 64px;
		-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		-ms-grid-rows: auto;
		grid-template-rows: auto;
	}

	.navbar {
		min-height: 72px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.nav-link {
		display: block;
		margin-right: 0px;
		margin-left: 0px;
		padding: 12px 36px;
		color: #151515;
		font-size: 17px;
		line-height: 24px;
		text-transform: none;
	}

	.navigation {
		grid-auto-columns: 1fr;
		-ms-grid-columns: auto auto;
		grid-template-columns: auto auto;
	}

	.nav-right {
		padding-right: 0px;
	}

	.page-heading {
		font-size: 5.6vw;
		line-height: 7vw;
	}

	.panel {
		grid-column-gap: 24px;
	}

	.circle-contact-info {
		padding-right: 8px;
		padding-left: 8px;
	}

	.panel-email {
		font-size: 15px;
		line-height: 20px;
	}

	.panel-service {
		margin-right: 44px;
		font-size: 13px;
		line-height: 20px;
	}

	.top-section {
		position: static;
		padding-top: 120px;
		padding-bottom: 112px;
	}

	.footer {
		padding: 5vw;
		grid-row-gap: 40px;
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
		-ms-grid-rows: auto auto auto;
		grid-template-rows: auto auto auto;
	}

	.footer-bottom {
		padding: 3vw 5vw;
	}

	.footer-links {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.hover-line {
		display: none;
	}

	.breadcrumb-divider {
		margin-right: 8px;
		margin-left: 8px;
	}

	.footer-link {
		margin-right: 20px;
	}

	.hero-v1-section {
		padding-top: 10vw;
		padding-bottom: 12vw;
	}

	.hero-v1 {
		padding-right: 2%;
		padding-left: 2%;
	}

	.hero-v1-photo {
		width: 30vw;
		height: 30vw;
		max-height: none;
		max-width: none;
	}

	.hero-v1-info {
		padding-top: 32px;
		padding-left: 5%;
		font-size: 26px;
		line-height: 36px;
	}

	.dynamic-text-wrapper {
		margin-bottom: 12px;
	}

	.works-grid-1 {
		grid-row-gap: 64px;
	}

	.works-grid-2 {
		// margin-left: 64px;
		grid-row-gap: 64px;
	}

	.card-hover-circle {
		display: none;
	}

	.intro-left.sticky {
		position: static;
	}

	.side-link-hover {
		display: none;
	}

	.dynamic-review-text {
		font-size: 15px;
		line-height: 24px;
	}

	.dynamic-review-avatar {
		width: 72px;
		height: 72px;
		margin-right: 20px;
	}

	.dinamic-review-info {
		margin-top: 4px;
		font-size: 13px;
		line-height: 20px;
	}

	.counters {
		max-width: 440px;
		padding-top: 0px;
		padding-bottom: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.counter {
		padding: 48px 10%;
		box-shadow: 1px 1px 0 0 #e4e8ed;
	}

	.testimonials {
		padding: 9vw 6vw;
	}

	.testimonials-circle {
		width: 70vw;
		height: 70vw;
	}

	.review-text {
		font-size: 20px;
		line-height: 32px;
	}

	.client-logos {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.client-logo {
		height: 28px;
		margin: 16px;
	}

	.pricing {
		max-width: 60vw;
		grid-row-gap: 48px;
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
	}

	.nav-menu {
		z-index: 10;
		width: 300px;
		padding-top: 88px;
		background-color: var(--color-default);
		text-align: left;
	}

	.circle-wrapper {
		width: 72px;
		height: 72px;
	}

	.instagram {
		padding-top: 24px;
		padding-bottom: 24px;
	}

	.instagram-info {
		width: 192px;
	}

	.instagram-photos {
		padding-left: 24px;
		grid-column-gap: 8px;
	}

	.instagram-heading {
		font-size: 24px;
		line-height: 32px;
	}

	.instagram-hover {
		display: none;
	}

	.posts {
		grid-column-gap: 24px;
		grid-row-gap: 32px;
	}

	.post-card-content {
		margin-top: -24px;
		margin-right: 5%;
		margin-left: 5%;
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.side-blocks {
		overflow: hidden;
		grid-row-gap: 48px;
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
	}

	.side-info {
		padding-right: 8%;
		padding-left: 8%;
	}

	.location {
		left: 16px;
		bottom: 16px;
	}

	.services {
		height: 66vw;
		max-height: none;
		margin-top: 0px;
		margin-bottom: 0px;
		grid-column-gap: 16px;
		grid-row-gap: 16px;
	}

	.shop-grid {
		grid-column-gap: 16px;
		grid-row-gap: 32px;
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
	}

	.shop-item {
		height: 100%;
	}

	.shop-item.slider-item:hover {
		box-shadow: none;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.shop-item-content {
		width: 100%;
	}

	.cart-button {
		padding-bottom: 13px;
	}

	.shop-promo {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
	}

	.shop-slider {
		margin-bottom: -24px;
	}

	.shop-slider-intro {
		margin-bottom: 40px;
		padding-right: 16%;
		padding-left: 16%;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.product {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.product-sidebar {
		width: 100%;
		padding-top: 80px;
		padding-right: 25%;
		padding-left: 25%;
		border-left-style: none;
	}

	.product-gallery {
		padding-right: 0px;
	}

	.product-info {
		position: static;
	}

	.works {
		grid-column-gap: 32px;
		grid-row-gap: 60px;
	}

	.post-content {
		max-width: 92%;
		padding-right: 48px;
		padding-left: 48px;
	}

	.post-heading {
		font-size: 32px;
		line-height: 40px;
	}

	.post-header {
		padding: 32px 16px;
	}

	.post-image {
		height: 64vw;
		max-height: none;
	}

	.contact-form {
		grid-column-gap: 16px;
	}

	.order-sidebar {
		top: 100px;
		width: 260px;
	}

	.info-box {
		max-width: 500px;
		margin-right: auto;
		margin-left: auto;
		padding-top: 0px;
		padding-bottom: 0px;
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
	}

	.info-box-left {
		padding-top: 40px;
		padding-right: 0%;
		padding-bottom: 40px;
		box-shadow: 0 1px 0 0 #e4e8ed;
	}

	.info-box-right {
		padding: 40px 0%;
	}

	.blog-tags-heading {
		margin-top: 3px;
	}

	.category-list {
		margin-bottom: 64px;
	}

	.category-link {
		margin-right: 14px;
		margin-left: 14px;
		font-size: 17px;
		line-height: 20px;
	}

	.work-intro {
		margin-bottom: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.work-decription {
		width: 80%;
		margin-bottom: 24px;
		padding-right: 0%;
	}

	.work-nav-link {
		padding-right: 0px;
		padding-left: 0px;
	}

	.panel-contact-icon {
		width: 20px;
	}

	.work-nav-preview {
		width: 88px;
		height: 88px;
	}

	.work-nav-info {
		padding-right: 20px;
		padding-left: 20px;
	}

	.work-nav-name {
		font-size: 17px;
		line-height: 24px;
	}

	.work-block {
		padding-right: 40px;
		padding-left: 40px;
	}

	.work-block-heading {
		margin-bottom: 12px;
	}

	.work-block-info {
		font-size: 15px;
		line-height: 20px;
	}

	.work-main-preview {
		height: 60vw;
		max-height: none;
	}

	.utility-page-content {
		width: 340px;
		padding: 48px;
	}

	.lock-icon {
		width: 20px;
	}

	.order-content {
		margin-right: 14px;
	}

	.menu-icon-line-top {
		border-radius: 1px;
	}

	.menu-icon-line-bottom {
		border-radius: 1px;
	}

	.menu-icon-line-middle {
		border-radius: 1px;
	}

	.menu-button {
		z-index: 150;
		background-color: transparent;
	}

	.menu-button.w--open {
		background-color: transparent;
	}

	.work-nav-hover {
		display: none;
	}

	.sg-colors {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		-ms-grid-columns: 1fr 1fr 1fr;
		grid-template-columns: 1fr 1fr 1fr;
	}

	.sg-color {
		height: 18vw;
		max-height: none;
	}

	.sg-typography {
		padding-top: 0px;
	}
	.aboutme-grid {
		grid-row-start: span 1;
		grid-row-end: span 1;
		grid-column-start: span 6;
		grid-column-end: span 6;
	}
	.experience-grid {
		grid-column-start: span 7;
		grid-column-end: span 7;
		grid-row-start: span 1;
		grid-row-end: span 1;
	}
	.panel-services {
		grid-row-start: span 1;
		grid-row-end: span 1;
		grid-column-start: span 1;
		grid-column-end: span 1;
	}
	.section.no-padding-top.skillset{
		padding-top: 10rem;
	}
}
