.menubar-fit {
	background-color: var(--color-primary);
	padding-top: 2rem;
	padding-right: 1rem;
	padding-left: 1rem;
}
.text-menu {
	color: var(--color-menu-logo);
}
.navbar-brand.logo-name {
	font-size: 2rem;
	font-weight: 300;
	font-family: sans-serif;
	color: var(--color-menu-logo);
}

.menu {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	list-style: none;
	padding: 1rem;
	.menu-item {
		list-style: none;

		.menu-link {
			text-decoration: none;
			color: var(--color-menu-link);
			font-size: 1.6rem;
			display: block;
			padding: 10px 15px;
			cursor: pointer;
			position: relative;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 2px;
				background-color: var(--color-menu-link);
				transform: scaleX(0);
				transition: all 0.5s;
				transform-origin: left;
			}
			&:hover:before,
			&:hover:after,
			&:hover {
				transform: scaleX(1);
				color: var(--color-menu-link);
			}
			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 2px;
				background-color: var(--color-menu-link);
				transform: scaleX(0);
				transition: all 0.5s;
				transform-origin: right;
			}
		}
	}
}
.menu-bar{
	min-height: 3rem;
}
.dark-mode-icon {
	color: var(--color-dark-icon);
}
.menu-toggle {
	color: var(--color-menu-link);
	font-size: 2.6rem;
}
