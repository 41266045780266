.button {
	display: flex;
	height: 48px;
	padding: 13px 24px;
	justify-content: center;
	align-items: center;
	border-style: none;
	border-radius: 24px;
	background-color: var(--color-red);
	transition: color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
		background-color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
		border-color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	color: var(--color-default);
	font-size: 15px;
	line-height: 20px;
	font-weight: 700;
	text-align: center;
	text-decoration: none;
}
