.contact-form {
	background-color: var(--color-contact-form);
	padding: 2rem;
}
.contact-form-content {
	padding: 3rem;
}

.contact-content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.basic-contactinfo-text {
	font-size: 1.5rem;
}
.text-contact-second {
	color: var(--color-text-contact-second);
}
.container-social {
	text-align: center;
	display: flex;
}
.icon {
	width: 40px;
	height: 40px;
	background: var(--color-social-icon);
	margin: 10px;
	border-radius: 30%;
	color: var(--color-red);
	box-shadow: 0 5px 15px -5px var(--color-social-icon-shadow);
	position: relative;
	overflow: hidden;
}
.icon i {
	line-height: 40px;
	font-size: 25px;
	transition: 0.2s;
}
.icon:hover i {
	transform: scale(1.3);
	color: var(--color-social-icon);
}
.icon::before {
	content: "";
	position: absolute;
	width: 120%;
	height: 120%;
	background: var(--color-red);
	left: -110%;
	top: 90%;
	transform: rotate(45deg);
}
.icon:hover::before {
	animation: animation 0.7s 1 forwards;
}
@keyframes animation {
	0% {
		left: -110%;
		top: 90%;
	}
	50% {
		left: 15%;
		top: -30%;
	}
	100% {
		top: -10%;
		left: -10%;
	}
}
