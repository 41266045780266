.wrapper {
	position: relative;
	z-index: 2;
	width: 100%;
	max-width: 1234px;
	margin-right: auto;
	margin-left: auto;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: transparent;
}

.page-wrapper {
	position: relative;
	width: 100%;
}

.section {
	position: relative;
	background-color: var(--color-section-background);
	z-index: 3;
	padding-top: 10rem;
	padding-bottom: 10rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.section.no-padding {
	padding-top: 0px;
	padding-bottom: 0px;
}

.section.no-padding-top.skillset {
	padding-top: 10rem;
}

.section.post-section {
	overflow: hidden;
}

.section.border-top {
	border-top: 1px solid #e4e8ed;
}

.main-grid {
	position: relative;
	display: grid;
	width: 100%;
	grid-auto-columns: 1fr;
	grid-column-gap: 30px;
	grid-row-gap: 64px;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	perspective: 2000px;
}

.intro {
	position: relative;
	z-index: 1;

	display: flex;
	max-width: 570px;
	margin-right: auto;
	margin-left: auto;

	flex-direction: column;

	align-items: center;
	text-align: center;
}

.intro.margin {
	margin-bottom: 64px;
}

.intro.margin-small {
	margin-bottom: 48px;
}

.intro.wide {
	max-width: 770px;
}

.intro-left.sticky {
	position: sticky;
	top: 120px;
}

.panel {
	display: -ms-grid;
	display: grid;
	width: 100%;
	padding-top: 32px;
	padding-bottom: 32px;

	justify-content: space-between;
	grid-auto-columns: 1fr;
	grid-column-gap: 30px;
	grid-row-gap: 0px;

	grid-template-columns: auto auto;

	grid-template-rows: auto;
	border-bottom: 1px solid var(--color-red);
}

.panel-contact {
	display: flex;
	align-items: center;
	color: #151515;
	text-align: right;
}

.pulse-button {
	position: relative;
	width: 100px;
	height: 100px;
	border: none;
	box-shadow: 0 0 0 0 var(--color-red-shadow);
	cursor: pointer;
	-webkit-animation: none;
	-moz-animation: none;
	-ms-animation: none;
	animation: none;
}

.pulse-button:hover {
	-webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
	-moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
	-ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
	animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

@-webkit-keyframes pulse {
	to {
		box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
	}
}
@-moz-keyframes pulse {
	to {
		box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
	}
}
@-ms-keyframes pulse {
	to {
		box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
	}
}
@keyframes pulse {
	to {
		box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
	}
}
.circle-wrapper {
	position: relative;
	display: flex;
	width: 80px;
	height: 80px;
	padding: 12px;
	justify-content: center;
	align-items: center;
	flex: 0 0 auto;
	transition: all 3s;
	&:hover {
		.circle-wave-1,
		.circle-wave-2 {
			transform: translate3d(0px, 0px, 0px) scale3d(1.14, 1.14, 1.14)
				rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
		}
	}
}
.circle-wave-1,
.circle-wave-2 {
	transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
		rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
	transform-style: preserve-3d;
}
.circle {
	position: relative;
	z-index: 3;
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: var(--color-red);
}

.circle-wave-1 {
	position: absolute;
	left: 8px;
	top: 8px;
	right: 8px;
	bottom: 8px;
	z-index: 2;
	margin: auto;
	border-radius: 50%;
	background-color: var(--color-red);
	opacity: 0.2;
}

.circle-wave-2 {
	position: absolute;
	left: 4px;
	top: 4px;
	right: 4px;
	bottom: 4px;
	z-index: 1;
	border-radius: 50%;
	background-color: var(--color-red);
	opacity: 0.1;
}

.circle-contact-info {
	padding-right: 12px;
	padding-left: 12px;
}

.panel-text-label {
	margin-bottom: 4px;
	color: var(--color-panel-text);
	font-size: 13px;
	line-height: 20px;
}

.panel-email {
	font-size: 17px;
	font-weight: 600;
}

.panel-services {
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.panel-service {
	margin-right: 80px;
	font-size: 14px;
	line-height: 24px;
	font-weight: 700;
	text-transform: uppercase;
}

.link-reset:link {
	text-decoration: none;
}
.text-small{
	font-size: 1.5rem;
}