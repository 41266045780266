.text-first {
	color: var(--color-tertairy);
}
.text-second{
	color: var(--color-secondary);
}
h1 {
	margin-top: 0px;
	margin-bottom: 16px;
	font-size: 48px;
	line-height: 60px;
	font-weight: 700;
}

h2 {
	margin-top: 0px;
	margin-bottom: 16px;
	font-size: 36px;
	line-height: 48px;
	font-weight: 700;
}

h3 {
	margin-top: 0px;
	margin-bottom: 12px;
	font-size: 24px;
	line-height: 32px;
	font-weight: 700;
}

h4 {
	margin-top: 0px;
	margin-bottom: 12px;
	font-size: 20px;
	line-height: 28px;
	font-weight: 700;
}

h5 {
	margin-top: 0px;
	margin-bottom: 16px;
	font-size: 17px;
	line-height: 24px;
	font-weight: 700;
}

h6 {
	margin-top: 0px;
	margin-bottom: 16px;
	font-size: 15px;
	line-height: 20px;
	font-weight: 700;
}

// p {
// 	margin-bottom: 20px;
// }

a {
	color: var(--color-red);
	text-decoration: none;
}

img {
	display: inline-block;
	max-width: 100%;
}

.text-label {
	display: inline-block;
	margin-bottom: 12px;
	border-radius: 4px;
	color:var(--color-red);
	font-size: 11px;
	line-height: 16px;
	font-weight: 600;
	letter-spacing: 0.8px;
	text-transform: uppercase;
}

.text-grey {
	color: #666d7a;
}

.text-grey-2 {
	color: #666;
}
