.hover-line {
	width: 100%;
	transform: translate3d(-102%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
		rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
	transform-style: preserve-3d;
}

.footer-section {
	background-color:var(--color-tertairy);
	color: var(--color-default);
}

.footer {
	display: grid;
	padding: 3vw;
	grid-auto-columns: 1fr;
	grid-column-gap: 8vw;
	grid-row-gap: 16px;
	grid-template-columns: 1fr auto auto;
	grid-template-rows: auto;
}

.footer-nav {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	align-items: center;
}
.copy-rights {
	width: 2rem;
	height: 2rem;
}
.copyrights {
	font-size: 1.4rem;
}
.footer-credits {
	display: flex;
	flex-direction: column;
	font-size: 1.4rem;
}
