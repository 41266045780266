@media screen and (max-width: 479px) {
	blockquote {
		padding-right: 24px;
		padding-left: 22px;
	}

	.main-grid {
		grid-column-gap: 0px;
		grid-row-gap: 48px;
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
	}

	.navbar {
		z-index: 100;
	}

	.page-heading {
		font-size: 10vw;
		line-height: 12vw;
	}

	.panel {
		padding-top: 24px;
		padding-bottom: 24px;
		grid-row-gap: 12px;
		-ms-grid-columns: auto;
		grid-template-columns: auto;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
	}

	.panel-contact {
		margin-right: 0px;
		flex-direction: row-reverse;
		text-align: left;
	}

	.panel-text-label {
		margin-bottom: 4px;
		font-size: 13px;
		line-height: 20px;
	}

	.panel-email {
		font-size: 17px;
		line-height: 24px;
	}

	.panel-services {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.panel-service {
		margin-top: 12px;
		margin-right: 0px;
		margin-bottom: 12px;
		font-size: 15px;
		line-height: 24px;
	}

	.panel-right {
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.panel-social-links {
		margin-right: 0px;
		margin-left: -10px;
	}

	.hero-v1-section {
		margin-bottom: -32px;
		padding-top: 32vw;
		padding-bottom: 26vw;
	}

	.hero-v1 {
		padding-right: 0%;
		padding-left: 0%;
		padding-bottom: 0%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.hero-v1-photo {
		width: 45vw;
		height: 45vw;
	}

	.hero-v1-info {
		padding-top: 16px;
		padding-left: 0%;
	}

	.hero-v1-angle {
		display: none;
	}

	.dynamic-text-wrapper {
		padding-right: 0px;
		padding-left: 0px;
	}

	.skill-big {
		margin: 12px;
	}

	.dynamic-works {
		padding-right: 0%;
	}

	.dynamic-works-heading {
		margin-bottom: 16px;
	}

	.side-link {
		font-size: 15px;
		line-height: 20px;
	}

	.dynamic-works-intro {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.dynamic-testimonial {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.dynamic-review-text {
		font-size: 17px;
		line-height: 28px;
	}

	.dynamic-review-avatar {
		margin-right: 0px;
		margin-bottom: 16px;
	}

	.dinamic-review-info {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.career-date {
		grid-area: career-date;
	}
	.timeline-grid {
		align-self: stretch;
		justify-self: center;
		grid-area: timeline;
	}
	.job-info {
		grid-area: job-info;
	}
	.career-block {
		padding-left: 8px;
		grid-column-gap: 32px;
		grid-row-gap: 8px;
		grid-template-areas: "timeline career-date career-date" "timeline job-info job-info";
		-ms-grid-rows: auto 8px auto;
		grid-template-rows: auto auto;
	}

	.career-date {
		padding-top: 5px;
		padding-bottom: 5px;
		text-align: left;
	}

	.counters {
		width: 100%;
		max-width: none;
	}

	.counter {
		padding-right: 0%;
		padding-left: 0%;
	}

	.testimonials {
		padding: 14vw 8vw;
	}

	.testimonials-circle {
		display: none;
	}

	.pricing {
		max-width: none;
		grid-row-gap: 40px;
	}

	.pricing-table {
		padding: 40px 24px;
	}

	.pricing-list {
		margin-top: 32px;
		padding-top: 24px;
	}

	.instagram {
		padding-top: 0px;
		padding-bottom: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-bottom-style: none;
	}

	.instagram-info {
		width: 100%;
		padding-top: 24px;
		padding-bottom: 24px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-right-style: none;
		text-align: center;
	}

	.instagram-photos {
		padding-left: 0px;
		grid-column-gap: 8px;
		grid-row-gap: 8px;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
	}

	.instagram-heading {
		font-size: 24px;
		line-height: 32px;
	}

	.post-card-content {
		margin-top: -24px;
		margin-right: 4%;
		margin-left: 4%;
		padding-right: 10%;
		padding-left: 10%;
	}

	.line-header {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.line-header-info {
		margin-bottom: 16px;
		padding-right: 0px;
	}

	.side-blocks {
		grid-row-gap: 40px;
	}

	.side-info {
		padding-right: 16px;
		padding-left: 16px;
	}

	.location {
		left: 8px;
		bottom: 8px;
	}

	.location-icon {
		padding: 16px;
	}

	.location-info {
		padding: 4px 16px;
	}

	.location-flag {
		width: 24px;
	}

	.location-text {
		font-size: 13px;
		line-height: 20px;
	}

	.services {
		height: auto;
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
	}

	.shop-item-content {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.shop-item-price-box {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 12px 8px 0px 0px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: left;
	}

	.shop-item-price-old {
		margin-top: 0px;
		margin-left: 12px;
	}

	.shop-slider {
		padding-right: 0px;
		padding-left: 0px;
	}

	.shop-slider-arrow {
		display: none;
	}

	.shop-slide {
		padding-top: 32px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.shop-slider-intro {
		padding-right: 0%;
		padding-left: 0%;
	}

	.product-sidebar {
		padding-top: 48px;
		padding-right: 0%;
		padding-left: 0%;
	}

	.product-gallery-grid {
		padding-top: 16px;
		grid-row-gap: 16px;
	}

	.works {
		grid-row-gap: 41px;
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
	}

	.post-content {
		max-width: none;
		margin-top: 8px;
		padding-right: 16px;
		padding-left: 16px;
	}

	.post-top-border {
		left: 0px;
		top: 10px;
		right: 0px;
	}

	.post-header {
		padding-right: 0px;
		padding-left: 0px;
	}

	.contact-form {
		-ms-grid-columns: 100%;
		grid-template-columns: 100%;
	}

	.blog-tags {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.category-list {
		overflow: hidden;
		width: 100%;
		height: auto;
		margin-top: 8px;
		padding-top: 0px;
		padding-bottom: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		border-style: solid;
		border-width: 1px;
		border-color: #e4e8ed;
		border-radius: 24px;
		background-color: #fff;
	}

	.list-dropdown-heading {
		position: relative;
		z-index: 1;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 48px;
		padding: 0px 20px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		color: #151515;
		text-align: center;
		cursor: pointer;
	}

	.categories {
		padding-bottom: 9px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.category-link {
		height: 48px;
		font-size: 15px;
	}

	.work-intro {
		padding-top: 32px;
		padding-bottom: 32px;
	}

	.work-decription {
		width: 100%;
	}

	.work-nav {
		padding-top: 0px;
		padding-bottom: 0px;
		grid-row-gap: 0px;
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
		-ms-grid-rows: auto auto auto;
		grid-template-rows: auto auto auto;
		border-top-style: none;
		border-bottom-style: none;
		text-align: center;
	}

	.work-nav-link {
		padding-top: 20px;
		padding-bottom: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.work-nav-link.right {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
		text-align: center;
	}

	.work-nav-preview {
		width: 44vw;
		height: 44vw;
	}

	.work-nav-name {
		font-size: 17px;
		line-height: 24px;
	}

	.work-nav-text {
		margin-top: 4px;
		margin-bottom: 4px;
	}

	.service {
		height: 120vw;
	}

	.service-icons {
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
	}

	.service-icon-box {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		text-align: left;
	}

	.service-icon {
		margin-right: 24px;
		margin-bottom: 0px;
	}

	.work-blocks {
		width: 100%;
	}

	.work-block {
		padding-right: 24px;
		padding-left: 24px;
	}

	.work-categories {
		margin-right: 0px;
	}

	.work-main-preview {
		height: 90vw;
	}

	.utility-page-content {
		width: 90%;
		padding: 40px;
	}

	.licence {
		padding-top: 48px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.licence-info {
		margin-top: 8px;
		padding-left: 0px;
		text-align: center;
	}

	.cart-list {
		padding: 4px 16px;
	}

	.cart-footer {
		padding-right: 16px;
		padding-left: 16px;
	}

	.sg-colors {
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
	}

	.sg-color {
		height: 24vw;
	}
	.contact-content {
		flex-direction: column;
	}
	.menubar-fit {
		padding-top: 0%;
	}
	.homebottom {
		padding-bottom: 0%;
	}
	.section.no-padding-top.skillset {
		padding-top: 2rem;
	}
	.skill-progress-init {
		transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
			rotateY(0deg) rotateZ(0deg) skew(0deg);
		transform-style: preserve-3d;
	}
}
