.career-block {
	display: grid;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-areas: "career-date timeline job-info";
	grid-template-columns: auto minmax(32px, auto) 1fr;
	grid-template-rows: auto;
}

.timeline {
	position: relative;
	display: flex;
	width: 2px;
	flex-direction: column;
	align-items: center;
	background-color: var(--color-timeline);
}

.timeline-dot {
	position: absolute;
	left: auto;
	top: 0%;
	right: auto;
	bottom: auto;
	z-index: 2;
	width: 8px;
	height: 8px;
	margin-top: 12px;
	margin-right: 0px;
	margin-left: 0px;
	border-radius: 50%;
	background-color: var(--color-red);
	box-shadow: 0 0 0 6px var(--color-timeline-dot),
		0 0 0 12px var(--color-default);
}

.job-info {
	padding-bottom: 48px;
}

.career-date {
	width: 120px;
	padding-top: 6px;
	padding-bottom: 6px;
	font-size: 13px;
	line-height: 20px;
	text-align: right;
}

.job-description {
	margin-bottom: 12px;
	color: var(--color-job-description);
	font-size: 13px;
	line-height: 20px;
}

.job-heading {
	margin-bottom: 8px;
}
.tag-badge {
	display: inline-block;
	margin-top: 6px;
	margin-right: 6px;
	margin-bottom: 6px;
	padding: 2px 11px;
	border-radius: 32px;
	background-color: var(--color-tag-badge);

	transition: background-color 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
		color 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	color: var(--color-tag-badge-text);
	font-size: 11px;
	line-height: 20px;
	font-weight: 600;
	text-transform: uppercase;
}
.tag-badge:hover {
	background-color: var(--color-red);
	color: var(--color-default);
}

.tag-badge.job-badge {
	margin-top: 6px;
	margin-right: 0px;
	margin-bottom: 0px;
	cursor: default;
}

.career-grid {
	align-self: start;
	justify-self: stretch;
}

.timeline-grid {
	align-self: stretch;
	justify-self: center;
}

.experience-grid {
	// grid-row-start: 1;
	-ms-grid-row-span: 1;
	// grid-row-end: 2;

	grid-column-start: 6;
	-ms-grid-column-span: 7;
	grid-column-end: 13;
}

.aboutme-grid {
	grid-row-start: span 1;
	grid-row-end: span 1;
	grid-column-start: span 4;
	grid-column-end: span 4;
	align-self: start;
}
