.home-fit {
	background-color: var(--color-primary);
}
.homebottom {
	background-color: var(--color-secondary);
	padding-bottom: 15rem;
}

.hero-v1-section {
	position: relative;
	overflow: hidden;
	padding-top: 5vw;
	padding-bottom: 5vw;
}

.hero-v1 {
	display: flex;
	align-items: stretch;
	padding-bottom: 10rem;
}
.hero-v1-text {
	color: var(--color-tertairy);
}

.hero-v1-angle {
	position: absolute;
	left: -10vw;
	bottom: -5vw;
	z-index: 1;
	width: 120vw;
	height: 28vw;
	margin-right: auto;
	margin-left: auto;
	background-color: var(--color-secondary);
	-webkit-transform: translate(0px, 13vw) rotate(9deg);
	-ms-transform: translate(0px, 13vw) rotate(9deg);
	transform: translate(0px, 13vw) rotate(9deg);
}

.hero-v1-photo {
	height: 30rem;
	width: 30rem;
	z-index: 100;
	max-height: 24vw;
	max-width: 24vw;

	flex: 0 0 auto;
	border-radius: 50%;
	-o-object-fit: cover;
	object-fit: cover;
}

.hero-v1-info {
	padding-top: 60px;
	padding-left: 8%;
	// padding-right: 15rem;
	flex: 1;
	font-size: 36px;
	line-height: 48px;
	font-weight: 700;
}

.dynamic-text-wrapper {
	position: relative;
	z-index: 1;
	overflow: hidden;
	height: 48px;
}

.slidingVertical {
	display: inline;
}
.slidingVertical span {
	animation: topToBottom 12.5s linear infinite 0s;
	-ms-animation: topToBottom 12.5s linear infinite 0s;
	-webkit-animation: topToBottom 12.5s linear infinite 0s;
	color: var(--color-red);
	opacity: 0;
	overflow: hidden;
	position: absolute;
}
.slidingVertical span:nth-child(2) {
	animation-delay: 2.5s;
}
.slidingVertical span:nth-child(3) {
	animation-delay: 5s;
}
.slidingVertical span:nth-child(4) {
	animation-delay: 7.5s;
}
.slidingVertical span:nth-child(5) {
	animation-delay: 10s;
}
/*topToBottom Animation*/
@keyframes topToBottom {
	0% {
		opacity: 0;
	}
	5% {
		opacity: 0;
		transform: translateY(-50px);
	}
	10% {
		opacity: 1;
		transform: translateY(0px);
	}
	25% {
		opacity: 1;
		transform: translateY(0px);
	}
	30% {
		opacity: 0;
		transform: translateY(50px);
	}
	80% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

.panel-services {
	display: flex;
	align-items: center;
}
.panel-contact {
	display: flex;
	align-items: center;
	color: var(--color-panel-contact);
	text-align: right;
	flex-direction: row-reverse;
}
.w-inline-block {
	max-width: 100%;
	display: inline-block;
}
