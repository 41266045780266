.skills-big {
	display: flex;
	max-width: 770px;
	margin-top: -10px;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
}

.skill-big {
	position: relative;

	display: flex;
	width: 120px;
	height: 120px;
	margin: 10px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color:var(--color-skill-circle);
	text-align: center;
	cursor: pointer;
}

.skill-big-icon {
	height: 44px;
}

.skill-text {
	margin-top: 8px;
	font-size: 11px;
	line-height: 14px;
	font-weight: 600;
}

.skill-progress {
	position: absolute;
	bottom: -10px;
	display: flex;
	overflow: hidden;
	height: 24px;
	padding-right: 14px;
	padding-left: 14px;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
	background-color:var(--color-default);
	box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
	color: var(--color-skill-progress);
	font-size: 11px;
	line-height: 14px;
	font-weight: 600;
}

.skill-progress-init {
	transform: translate3d(0px, 0px, 0px) scale3d(0, 0, 1) rotateX(0deg)
		rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
	transform-style: preserve-3d;
	transition: all 0.5s;
}

/* SIBLING FADE: fade out siblings around a hovered item */

.skills-big {
	visibility: hidden;
}
/* Prevents :hover from triggering in the gaps between items */

.skills-big > * {
	visibility: visible;
}
/* Brings the child items back in, even though the parent is `hidden` */

.skills-big > * {
	transition: opacity 150ms linear 100ms, transform 150ms ease-in-out 100ms;
}
/* Makes the fades smooth with a slight delay to prevent jumps as the mouse moves between items */

.skills-big:hover > * {
	opacity: 0.4;
	transform: scale(0.9);

	.skill-progress-init {
		transform: translate3d(0px, 0px, 0px) scale3d(0, 0, 1) rotateX(0deg)
			rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
		transform-style: preserve-3d;
		transition: all 0.5s;
	}
}
/* Fade out all items when the parent is hovered */

.skills-big > *:hover {
	opacity: 1;
	transform: scale(1);
	transition-delay: 0ms, 0ms;
	.skill-progress-init {
		transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
			rotateY(0deg) rotateZ(0deg) skew(0deg);
		transform-style: preserve-3d;
	}
}
/* Fade in the currently hovered item */
