:root {
	--color-primary: #{$color-primary};
	--color-secondary: #{$color-secondary};
	--color-tertairy: #{$color-tertiary};
	--color-red: #{$color-red};
	--color-red-shadow: #{$color-red-shadow};
	--color-default: #{$color-default};
	--color-menu-logo: #{$color-menu-logo};
	--color-menu-link: #{$color-menu-link};
	--color-panel-text: #{$color-panel-text};
	--color-panel-contact: #{$color-panel-contact};
	--color-social-icon: #{$color-social-icon};
	--color-social-icon-shadow: #{$color-social-icon-shadow};
	--color-timeline: #{$color-timeline};
	--color-timeline-dot: #{$color-timeline-dot};
	--color-job-description: #{$color-job-description};
	--color-tag-badge: #{$color-tag-badge};
	--color-tag-badge-text: #{$color-tag-badge-text};
	--color-side-link-border: #{$color-side-link-border};
	--color-side-link: #{$color-side-link};
	--color-work-card-border: #{$color-work-card-border};
	--color-work-card-heading: #{$color-work-card-heading};
	--color-work-card-description: #{$color-work-card-description};
	--color-skill-circle: #{$color-skill-circle};
	--color-skill-progress: #{$color-skill-progress};
	--color-section-background: #{$color-section-background};
	--color-contact-form: #{$color-contact-form};
	--color-text-contact-second: #{$color-text-contact-second};
	--color-dark-icon: #{$color-dark-icon};
	--color-back-to-top: #{$color-back-to-top};
	--color-back-to-top-inner: #{$color-back-to-top-inner};
}
.dark {
	--color-primary: #{$color-primary-dark};
	--color-secondary: #{$color-secondary-dark};
	--color-tertairy: #{$color-tertiary-dark};
	--color-red: #{$color-red};
	--color-red-shadow: #{$color-red-shadow};
	--color-default: #{$color-default-dark};

	--color-menu-logo: #{$color-menu-logo-dark};
	--color-menu-link: #{$color-menu-link-dark};
	--color-panel-text: #{$color-panel-text-dark};
	--color-panel-contact: #{$color-panel-contact-dark};
	--color-social-icon: #{$color-social-icon-dark};
	--color-social-icon-shadow: #{$color-social-icon-shadow-dark};
	--color-timeline: #{$color-timeline-dark};
	--color-timeline-dot: #{$color-timeline-dot-dark};
	--color-job-description: #{$color-job-description-dark};
	--color-tag-badge: #{$color-tag-badge-dark};
	--color-tag-badge-text: #{$color-tag-badge-text-dark};
	--color-side-link-border: #{$color-side-link-border-dark};
	--color-side-link: #{$color-side-link-dark};
	--color-work-card-border: #{$color-work-card-border-dark};
	--color-work-card-heading: #{$color-work-card-heading-dark};
	--color-work-card-description: #{$color-work-card-description-dark};
	--color-skill-circle: #{$color-skill-circle-dark};
	--color-skill-progress: #{$color-skill-progress-dark};
	--color-section-background: #{$color-section-background-dark};
	--color-contact-form: #{$color-contact-form-dark};
	--color-text-contact-second: #{$color-text-contact-second-dark};
	--color-dark-icon: #{$color-dark-icon-dark};
	--color-back-to-top: #{$color-back-to-top-dark};
	--color-back-to-top-inner: #{$color-back-to-top-inner-dark};
	transition: all 0.15s ease;
}

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	font-size: 62.5%;
	scroll-behavior: smooth;
}

body {
	box-sizing: border-box;
}

#root {
	height: 100vh;
	background-color: var(--color-default);
	font-family: "Roboto", sans-serif;
}
